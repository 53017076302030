<template>
  <div id="dashboard">
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  name: "dashboard",
  data() {
    return {};
  },
};
</script>
